import React, { useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

const CookiePolicyPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("cookiePageTitle");
  }, []);

  return (
    <div className="kvkk">
      <div className="header">
        <h2>{t("CookieTitle")}</h2>
        <p>{t("CookieText1")}</p>
      </div>
      <div className="body">
        <h3>{t("CookieSubTitle1")}</h3>
        <p>{t("CookieText2")}</p>
        <p>{t("CookieText3")}</p>
        <p>{t("CookieText4")}</p>
        <h3>{t("CookieSubTitle2")}</h3>
        <p>{t("CookieText5")}</p>
        <h3>{t("CookieSubTitle3")}</h3>
        <p>{t("CookieText6")}</p>
        <p>{t("CookieText7")}</p>
      </div>
    </div>
  );
};

export default CookiePolicyPage;
