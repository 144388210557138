import React, { useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

const AboutPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("aboutPageTitle");
  }, []);

  return (
    <div>
      <section className="about">
        <div className="content">
          <h2>{t("aboutTitle")}</h2>
          <p>{t("aboutText")}</p>
        </div>
        <img src={"/assets/imgs/about.png"} alt="about" />
      </section>
      <section className="whoWeAre">
        <div className="left">
          <div className="item">
            <span>10+</span>
            <p>{t("aboutWhoAreWeBox1")}</p>
          </div>
          <div className="item">
            <span>20</span>
            <p>{t("aboutWhoAreWeBox2")}</p>
          </div>
          <div className="item">
            <span>3</span>
            <p>{t("aboutWhoAreWeBox3")}</p>
          </div>
          <div className="item">
            <span>10 {t("aboutWhoAreWeYear")}</span>
            <p>{t("aboutWhoAreWeBox4")}</p>
          </div>
        </div>
        <div className="right">
          <h6>{t("aboutWhoAreWe")}</h6>
          <h3>{t("aboutWhoAreWeTitle")}</h3>
          <p>{t("aboutWhoAreWeText")}</p>
        </div>
      </section>
      <section className="ourVision">
        <div className="content">
          <h6>{t("aboutOurVision")}</h6>
          <h3>{t("aboutOurVisionTitle")}</h3>
          <p>{t("aboutOurVisionText")}</p>
        </div>
        <img src={"/assets/imgs/ourVision.png"} alt="about" />
      </section>
    </div>
  );
};

export default AboutPage;
