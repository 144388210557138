import AppRouter from "routers/AppRouter";
import "assets/scss/index.scss";
import "./languages/i18n";
import { Toastify } from "components/toastify/toastify";

function App() {
  return (
    <>
      <Toastify />
      <AppRouter />
    </>
  );
}

export default App;
