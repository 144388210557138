import { useEffect, useRef, useState } from "react";
import "./index.scss";
import {
  ServicesIcon1,
  ServicesIcon2,
  ServicesIcon3,
  ServicesIcon4,
  ServicesIcon5,
  ServicesIcon6,
  ServicesSliderControl,
} from "../../assets/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Toast } from "components/toastify/toastify";

const HomePage = () => {
  const language = localStorage.getItem("language") ?? "tr";
  const hostname =
    window.location.hostname === "localhost"
      ? "http://localhost"
      : "https://www.deltasoftware.tech";
  const { t } = useTranslation();
  const swpierRef = useRef<{ swiper: any } | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [homeTitleCount, setHomeTitleCount] = useState<number>(0);
  const [selectedTechnologiesIndex, setSelectedTechnologiesIndex] =
    useState<number>(0);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    detail: "",
  });
  const [formServices, setFormServices] = useState<string[]>([]);
  const [formAgreement, setFormAgreement] = useState<boolean>(false);
  const [file, setFile] = useState<any>();

  const ourTechnologies = [
    {
      number: 1,
      title: "BACKEND",
      items: [
        {
          title: ".Net Core",
          image: "/assets/imgs/backend1.png",
        },
        {
          title: "Python",
          image: "/assets/imgs/backend2.png",
        },
        {
          title: "C#",
          image: "/assets/imgs/backend3.png",
        },
        {
          title: "Django",
          image: "/assets/imgs/backend4.png",
        },
        {
          title: "Java",
          image: "/assets/imgs/backend5.png",
        },
        {
          title: "Spring",
          image: "/assets/imgs/backend6.png",
        },
        {
          title: "Node.js",
          image: "/assets/imgs/backend7.png",
        },
      ],
    },
    {
      number: 2,
      title: "FRONTEND",
      items: [
        {
          title: "Ios",
          image: "/assets/imgs/frontend1.png",
        },
        {
          title: "Android",
          image: "/assets/imgs/frontend2.png",
        },
        {
          title: "Javascript",
          image: "/assets/imgs/frontend3.png",
        },
        {
          title: "React.js - React Native",
          image: "/assets/imgs/frontend4.png",
        },
        {
          title: "Flutter",
          image: "/assets/imgs/frontend5.png",
        },
      ],
    },
    {
      number: 3,
      title: "DEVOPS",
      items: [
        {
          title: "Kubernetes",
          image: "/assets/imgs/devops1.png",
        },
        {
          title: "Docker",
          image: "/assets/imgs/devops2.png",
        },
        {
          title: "Ansible",
          image: "/assets/imgs/devops3.png",
        },
        {
          title: "Chef",
          image: "/assets/imgs/devops4.png",
        },
        {
          title: "Grafana",
          image: "/assets/imgs/devops5.png",
        },
        {
          title: "Amazon Web Services",
          image: "/assets/imgs/devops6.png",
        },
      ],
    },
    {
      number: 4,
      title: "UI&UX",
      items: [
        {
          title: "Miro",
          image: "/assets/imgs/uix1.png",
        },
        {
          title: "Adobe After Effects",
          image: "/assets/imgs/uix2.png",
        },
        {
          title: "Adobe XD",
          image: "/assets/imgs/uix3.png",
        },
        {
          title: "Adobe Illustrator",
          image: "/assets/imgs/uix4.png",
        },
        {
          title: "Adobe Photoshop",
          image: "/assets/imgs/uix5.png",
        },
        {
          title: "Figma",
          image: "/assets/imgs/uix6.png",
        },
      ],
    },
  ];

  const faq = [
    {
      id: 1,
      title: t("faqItemTitle1"),
      content: t("faqItemAnswer1"),
    },
    {
      id: 2,
      title: t("faqItemTitle2"),
      content: t("faqItemAnswer2"),
    },
    {
      id: 3,
      title: t("faqItemTitle3"),
      content: t("faqItemAnswer3"),
    },
    {
      id: 4,
      title: t("faqItemTitle4"),
      content: t("faqItemAnswer4"),
    },
    {
      id: 5,
      title: t("faqItemTitle5"),
      content: t("faqItemAnswer5"),
    },
    {
      id: 6,
      title: t("faqItemTitle6"),
      content: t("faqItemAnswer6"),
    },
    {
      id: 7,
      title: t("faqItemTitle7"),
      content: t("faqItemAnswer7"),
    },
    {
      id: 8,
      title: t("faqItemTitle8"),
      content: t("faqItemAnswer8"),
    },
    {
      id: 9,
      title: t("faqItemTitle9"),
      content: t("faqItemAnswer9"),
    },
    {
      id: 10,
      title: t("faqItemTitle10"),
      content: t("faqItemAnswer10"),
    },
  ];

  const selectAccordion = (id: number) => {
    const element = document.getElementById(id.toString());
    if (element) {
      if (element.classList.contains("active")) {
        element.classList.remove("active");
      } else {
        element.classList.add("active");
      }
    }
  };

  const selectServices = (service: string) => {
    const item = formServices.find((x) => x === service);
    if (item) {
      const data = formServices.filter((x) => x !== service);
      setFormServices([...data]);
    } else {
      setFormServices([...formServices, service]);
    }
  };

  const handleFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  const sunbmitForm = async () => {
    if (formServices.length === 0) {
      Toast("error", t("apiServicesError"));
      return;
    }

    if (!formAgreement) {
      Toast("error", t("apiAgreementError"));
      return;
    }

    if (file) {
      let fileSizeMB = file.size / 1024 ** 2;
      if (parseInt(fileSizeMB.toString().split(".")[0]) >= 30) {
        Toast("error", t("fileSizeError"));
        return;
      }
    }

    let data = JSON.stringify({
      name: form.name,
      email: form.email,
      phone: form.phone,
      services: formServices,
      detail: form.detail,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${hostname}:6100/api/webForm/addForm`,

      headers: {
        apikey: "XH1vN8x3wihWEe0HIkZRxR4jw134WXScgWIu5G4C",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.S === "T") {
          if (file) {
            fileSave(response.data.file_uniq_id);
          } else {
            Toast("success", t("formSuccess"));
            setTimeout(() => {
              window.location.reload();
            }, 2200);
          }
        } else {
          Toast("error", t("formError"));
        }
      })
      .catch((error) => {
        console.log(error);
        Toast("error", t("formError"));
      });
  };

  const fileSave = async (file_uniq_id: string) => {
    if (!file) {
      return;
    }

    let data = JSON.stringify({
      file_uniq_id: file_uniq_id,
      file_ex: file.name.split(".").pop(),
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${hostname}:6100/api/webForm/addFile`,

      headers: {
        apikey: "XH1vN8x3wihWEe0HIkZRxR4jw134WXScgWIu5G4C",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.S === "T") {
          fileUpload(file_uniq_id + "." + file.name.split(".").pop());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fileUpload = async (name: string) => {
    if (!file) {
      return;
    }

    let formData = new FormData();
    formData.append("files", file, name);

    let config = {
      method: "post",
      url: `${hostname}:6100/api/fileUpload`,
      headers: {
        apikey: "XH1vN8x3wihWEe0HIkZRxR4jw134WXScgWIu5G4C",
        lowerpath: `form`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: formData,
    };

    axios
      .request(config)
      .then((response) => {
        Toast("success", t("formSuccess"));

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    document.title = t("homePageTitle");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setHomeTitleCount((prevCounter) => prevCounter + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home">
      <section className="homeBanner">
        <img src={"/assets/imgs/Delta3d.gif"} alt="home-banner" />
        <div className="content">
          <h2>
            {homeTitleCount % 2 === 0 ? "WE ARE DELTA SOFTWARE" : "0101010111"}
          </h2>
          <h1>{t("homeBannerTitle")}</h1>
        </div>
      </section>
      <section className="services">
        <h2 className="title">{t("servicesTitle")}</h2>
        <p className="text">{t("servicesText")}</p>
        <div className="items desktop-only">
          <div className="item">
            <div className="iconCont">
              <ServicesIcon1 />
            </div>
            <h3 className="title">{t("servicesItemTitle1")}</h3>
            <h5 className="text">{t("servicesItemText1")}</h5>
          </div>
          <div className="item">
            <div className="iconCont">
              <ServicesIcon2 />
            </div>
            <h3 className="title">{t("servicesItemTitle2")}</h3>
            <h5 className="text">{t("servicesItemText2")}</h5>
          </div>
          <div className="item">
            <div className="iconCont">
              <ServicesIcon3 />
            </div>
            <h3 className="title">{t("servicesItemTitle3")}</h3>
            <h5 className="text">{t("servicesItemText3")}</h5>
          </div>
          <div className="item">
            <div className="iconCont">
              <ServicesIcon4 />
            </div>
            <h3 className="title">{t("servicesItemTitle4")}</h3>
            <h5 className="text">{t("servicesItemText4")}</h5>
          </div>
          <div className="item">
            <div className="iconCont">
              <ServicesIcon5 />
            </div>
            <h3 className="title">{t("servicesItemTitle5")}</h3>
            <h5 className="text">{t("servicesItemText5")}</h5>
          </div>
          <div className="item">
            <div className="iconCont">
              <ServicesIcon6 />
            </div>
            <h3 className="title">{t("servicesItemTitle6")}</h3>
            <h5 className="text">{t("servicesItemText6")}</h5>
          </div>
        </div>
        <div className="items mobile-only">
          <div className="buttons">
            <div
              className={`button ${activeIndex === 0 ? "disable" : ""}`}
              onClick={() =>
                activeIndex > 0 ? swpierRef.current?.swiper.slidePrev() : {}
              }
            >
              <ServicesSliderControl />
            </div>
            <div
              className={`button ${activeIndex === 5 ? "disable" : ""}`}
              onClick={() =>
                activeIndex != 5 ? swpierRef.current?.swiper.slideNext() : {}
              }
            >
              <ServicesSliderControl />
            </div>
          </div>
          <Swiper
            onSlideChange={(swiper: any) => setActiveIndex(swiper.activeIndex)}
            ref={swpierRef}
            slidesPerView={1}
          >
            <SwiperSlide className="item">
              <div className="iconCont">
                <ServicesIcon1 />
              </div>
              <h3 className="title">{t("servicesItemTitle1")}</h3>
              <h5 className="text">{t("servicesItemText1")}</h5>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="iconCont">
                <ServicesIcon2 />
              </div>
              <h3 className="title">{t("servicesItemTitle2")}</h3>
              <h5 className="text">{t("servicesItemText2")}</h5>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="iconCont">
                <ServicesIcon3 />
              </div>
              <h3 className="title">{t("servicesItemTitle3")}</h3>
              <h5 className="text">{t("servicesItemText3")}</h5>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="iconCont">
                <ServicesIcon4 />
              </div>
              <h3 className="title">{t("servicesItemTitle4")}</h3>
              <h5 className="text">{t("servicesItemText4")}</h5>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="iconCont">
                <ServicesIcon5 />
              </div>
              <h3 className="title">{t("servicesItemTitle5")}</h3>
              <h5 className="text">{t("servicesItemText5")}</h5>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="iconCont">
                <ServicesIcon6 />
              </div>
              <h3 className="title">{t("servicesItemTitle6")}</h3>
              <h5 className="text">{t("servicesItemText6")}</h5>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="ourTechnologies">
        <h2 className="title">{t("ourTechnologiesTitle")}</h2>
        <p className="text">{t("ourTechnologiesText")}</p>
        <div className="content">
          <div className="categories">
            {ourTechnologies.map((item, index) => (
              <button
                onClick={() => setSelectedTechnologiesIndex(index)}
                className={
                  index === selectedTechnologiesIndex ? "item active" : "item"
                }
                key={index}
              >
                <div className="detail">
                  <div className="number">{item.number}</div>
                  <h5>{item.title}</h5>
                </div>
                <div className="line" />
              </button>
            ))}
          </div>
          <div className="items">
            <div className="items-content">
              {ourTechnologies[selectedTechnologiesIndex].items.map(
                (item, index) => (
                  <div className="item" key={index}>
                    <div className="img-container">
                      <img src={item.image} alt="technologies" />
                    </div>
                    <h6>{item.title}</h6>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="projectDesing">
        <h2 className="title">{t("projectDesingTitle")}</h2>
        <p className="text">{t("projectDesingText")}</p>
        <div className="content">
          <img
            className="desktop"
            src={
              language === "tr"
                ? "/assets/imgs/project-desing.png"
                : "/assets/imgs/project-desing-en.png"
            }
            alt="project-desing"
          />
          <img
            className="mobile"
            src={
              language === "tr"
                ? "/assets/imgs/project-desing-mobile.png"
                : "/assets/imgs/project-desing-mobile-en.png"
            }
            alt="project-desing"
          />
        </div>
      </section>
      <section className="faq">
        <h2 className="title">{t("faqTitle")}</h2>
        <p className="text">{t("faqText")}</p>
        <div className="content">
          {faq.map((item, index) => (
            <button
              onClick={() => selectAccordion(item.id)}
              className="accordion"
              id={item.id.toString()}
              key={index}
            >
              <div className="header">
                <h2>{item.title}</h2>
                <div className="icon">
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="body">
                <p>{item.content}</p>
              </div>
            </button>
          ))}
        </div>
      </section>
      <section className="projectForm">
        <h2 className="title">{t("projectFormTitle")}</h2>
        <p className="text">{t("projectFormText")}</p>
        <div className="content">
          <form
            onSubmit={(e) => {
              sunbmitForm();
              e.preventDefault();
            }}
          >
            <div className="input-group">
              <input
                type="text"
                required
                placeholder={t("projectFormInputName")}
                value={form.name}
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                }}
              />
              <input
                type="email"
                placeholder={t("projectFormInputEmail")}
                value={form.email}
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value });
                }}
              />
              <input
                type="tel"
                placeholder={t("projectFormInputTel")}
                value={form.phone}
                onChange={(e) => {
                  setForm({ ...form, phone: e.target.value });
                }}
              />
              <input
                type="file"
                onChange={handleFile}
                placeholder={t("projectFormInputFile")}
              />
            </div>
            <div className="services-input">
              <h6>{t("projectFormServices")}</h6>
              <div className="items">
                <label className="checkbox">
                  {t("projectFormCheckboxWeb")}
                  <input
                    type="checkbox"
                    onChange={() => selectServices("Web")}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox">
                  {t("projectFormCheckboxMobile")}
                  <input
                    type="checkbox"
                    onChange={() => selectServices("Mobile")}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox">
                  {t("projectFormCheckboxRPA")}
                  <input
                    type="checkbox"
                    onChange={() => selectServices("RPA")}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox">
                  {t("projectFormCheckboxCyber")}
                  <input
                    type="checkbox"
                    onChange={() => selectServices("Cyber Security")}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox">
                  {t("projectFormCheckboxGraphic")}
                  <input
                    type="checkbox"
                    onChange={() => selectServices("Graphic")}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox">
                  {t("projectFormCheckboxDigital")}
                  <input
                    type="checkbox"
                    onChange={() => selectServices("Digital")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="textarea">
              <h6>{t("projectFormDetail")}</h6>
              <textarea
                placeholder={t("projectFormTextArea")}
                value={form.detail}
                onChange={(e) => {
                  setForm({ ...form, detail: e.target.value });
                }}
              />
            </div>
            <div className="agreement">
              <label className="checkbox">
                {t("projectFormAgreement")}
                <input
                  type="checkbox"
                  onChange={() => setFormAgreement(!formAgreement)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="button-container">
              <button type="submit">{t("projectFormButton")}</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
