import { useTranslation } from "react-i18next";
import "./index.scss";
import parse from "html-react-parser";
import { useEffect } from "react";

const KvkkFormPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("kvkkFormPageTitle");
  }, []);

  return (
    <div className="kvkk">
      <div className="body">
        <h3> {t("kvkkFormTitle1")}</h3>
        <p>{parse(t("kvkkFormText1"))}</p>
        <h3>{t("kvkkFormTitle2")}</h3>
        <table>
          <thead>
            <tr>
              <th>
                <strong>{t("kvkkFormTableTh1")}</strong>
              </th>
              <th>
                <strong>{t("kvkkFormTableTh2")}</strong>
              </th>
              <th>
                <strong>{t("kvkkFormTableTh3")}</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("kvkkFormTableTr1Td1")}</td>
              <td>
                <strong> {t("kvkkFormTableTr1Td2")}</strong>
              </td>
              <td>{parse(t("kvkkFormTableTr1Td3"))}</td>
            </tr>
            <tr>
              <td>{t("kvkkFormTableTr2Td1")}</td>
              <td>
                <strong> {t("kvkkFormTableTr2Td2")}</strong>
              </td>
              <td>{parse(t("kvkkFormTableTr2Td3"))}</td>
            </tr>
            <tr>
              <td>{t("kvkkFormTableTr3Td1")}</td>
              <td>
                <strong> {t("kvkkFormTableTr3Td2")}</strong>
              </td>
              <td>{parse(t("kvkkFormTableTr3Td3"))}</td>
            </tr>
          </tbody>
        </table>
        <p>{parse(t("kvkkFormText2"))}</p>
        <h3>{t("kvkkFormTitle3")}</h3>
        <table>
          <tbody>
            <tr>
              <td>{t("kvkkFormTable2Th1")}</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("kvkkFormTable2Th2")}</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("kvkkFormTable2Th3")}</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("kvkkFormTable2Th4")}:</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("kvkkFormTable2Th5")}</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("kvkkFormTable2Th6")}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <h3>{t("kvkkFormTitle4")}:</h3>
        <p>{t("kvkkFormText3")}</p>
        <table>
          <thead>
            <tr>
              <th>{t("kvkkFormTable3Th1")}</th>
              <th>{t("kvkkFormTable3Th2")}</th>
              <th>{t("kvkkFormTable3Th3")}</th>
              <th>{t("kvkkFormTable3Th4")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{t("kvkkFormTable3Tr1Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr1Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>2</td>
              <td>{t("kvkkFormTable3Tr2Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr2Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>3</td>
              <td>{t("kvkkFormTable3Tr3Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr3Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>4</td>
              <td>{t("kvkkFormTable3Tr4Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr4Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>5</td>
              <td>{t("kvkkFormTable3Tr5Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr5Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>6</td>
              <td>{t("kvkkFormTable3Tr6Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr6Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>7</td>
              <td>{t("kvkkFormTable3Tr7Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr7Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>8</td>
              <td>{t("kvkkFormTable3Tr8Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr8Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>9</td>
              <td>{t("kvkkFormTable3Tr9Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr9Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>10</td>
              <td>{t("kvkkFormTable3Tr10Td1")}</td>
              <td>{parse(t("kvkkFormTable3Tr10Td2"))}</td>
              <td></td>
            </tr>
            <tr>
              <td>11</td>
              <td>{t("kvkkFormTable3Tr11Td1")}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <p>{t("kvkkFormText4")}</p>
        <table>
          <tbody>
            <tr>
              <td>{t("kvkkFormTable4Th1")}</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("kvkkFormTable4Th2")}</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("kvkkFormTable4Th3")}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <p>{parse(t("kvkkFormText5"))}</p>
      </div>
    </div>
  );
};

export default KvkkFormPage;
