import { useTranslation } from "react-i18next";
import "./index.scss";
import parse from "html-react-parser";
import { useEffect } from "react";

const KvkkPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("kvkkPageTitle");
  }, []);

  return (
    <div className="kvkk">
      <div className="header">
        <h2>{t("kvkkMainTitle")}</h2>
        <p>{t("kvkkText1")}</p>
        <h5>{t("kvkkSubTitle1")}</h5>
        <p>{t("kvkkText2")}</p>
        <p>{t("kvkkText3")}</p>
      </div>
      <div className="body">
        <h3>{t("kvkkSecondTitle1")}</h3>
        <p>{t("kvkkText4")}</p>
        <h3>{t("kvkkSecondTitle2")}</h3>
        <p>{t("kvkkText5")}</p>
        <h5>{t("kvkkSubTitle2")}</h5>
        <p>{t("kvkkText6")}</p>
        <h3>{t("kvkkSecondTitle3")}</h3>
        <p>{t("kvkkText7")}</p>
        <h3>{t("kvkkSecondTitle4")}</h3>
        <p>{t("kvkkText8")}</p>
        <h3>{t("kvkkSecondTitle5")}</h3>
        <p>{t("kvkkText9")}</p>
        <h3>{t("kvkkSecondTitle6")}</h3>
        <h5>{t("kvkkSubTitle3")}</h5>
        <p>{t("kvkkText10")}</p>
        <h5>{t("kvkkSubTitle4")}</h5>
        <p>{t("kvkkText11")}</p>
        <h5>{t("kvkkSubTitle5")}</h5>
        <p>{t("kvkkText12")}</p>
        <h5>{t("kvkkSubTitle6")}</h5>
        <p>{t("kvkkText13")}</p>
        <h5>{t("kvkkSubTitle7")}</h5>
        <p>{t("kvkkText14")}</p>
        <h5>{t("kvkkSubTitle8")}</h5>
        <p>{t("kvkkText15")}</p>
        <h5>{t("kvkkSubTitle9")}</h5>
        <p>{t("kvkkText16")}</p>
        <h3>{t("kvkkSecondTitle7")}</h3>
        <p>{t("kvkkText17")}</p>
        <h3>{t("kvkkSecondTitle8")}</h3>
        <p>{t("kvkkText18")}</p>
        <h3>{t("kvkkSecondTitle9")}</h3>
        <p>{t("kvkkText19")}</p>
        <h3>{t("kvkkSecondTitle10")}</h3>
        <p>{parse(t("kvkkText20"))}</p>
        <h3>{t("kvkkSecondTitle11")}</h3>
        <p>{t("kvkkText21")}</p>
        <h3>{t("kvkkSecondTitle12")}</h3>
        <p>{t("kvkkText22")}</p>
        <h3>{t("kvkkSecondTitle13")}</h3>
        <p>{t("kvkkText23")}</p>
        <h3>{t("kvkkSecondTitle14")}</h3>
        <p>{t("kvkkText24")}</p>
        <h3>{t("kvkkSecondTitle15")}</h3>
        <p>{t("kvkkText25")}</p>
        <h3>{t("kvkkSecondTitle16")}</h3>
        <p>{t("kvkkText26")}</p>
      </div>
    </div>
  );
};

export default KvkkPage;
