import { useTranslation } from "react-i18next";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("notFoundPageTitle");
  }, []);

  return (
    <div className="notFound">
      <img src={"assets/imgs/404.png"} alt="404" />
      <p>{t("NotFoundTitle")}</p>
      <div className="buton" onClick={() => navigate("/")}>
        <button>{t("NotFoundButton")}</button>
      </div>
    </div>
  );
};

export default NotFoundPage;
