import ComingSoon from "components/comingSoon";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { CloseIcon, FooterSearch, HeaderMenuStart, ServicesIcon1, ServicesIcon2, ServicesIcon3, ServicesIcon4, ServicesIcon5, ServicesIcon6 } from "assets/icons";

type Blog = {
  title: string;
  type: "gray" | "black" | "pink";
  category: "web_application" | "mobile_application" | "rpa_software" | "cyber_security" | "graphic_and_design" | "digital_marketing";
  detail: string;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const BlogPage = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState<Blog>();
  Modal.setAppElement('#root');

  useEffect(() => {
    document.title = t("blogPageTitle");
  }, []);

  const blogs: Blog[] = [
    { 
      title: "iOS Yazılımcı Hangi Yazılım Dillerini Kullanır?", 
      type: "pink", 
      category: "mobile_application", 
      detail: "iOS yazılım geliştirme, Apple'ın ekosisteminde çalışan uygulamalar oluşturmayı içerir ve genellikle Swift ile Objective-C dilleri kullanılarak yapılır. Swift, Apple tarafından modern bir dil olarak geliştirilmiş olup, hızlı ve güvenli uygulama geliştirme için tasarlanmıştır. Objective-C ise eski projelerde halen yaygın olarak kullanılan, daha klasik bir dildir. Bunun yanı sıra iOS geliştiriciler Xcode gibi güçlü bir geliştirme ortamından faydalanır. Bu IDE, hem tasarım hem de hata ayıklama araçlarıyla iOS geliştirme süreçlerini oldukça kolaylaştırır. Ayrıca, UIKit ve SwiftUI gibi frameworkler, kullanıcı arayüzlerini kolayca oluşturmak ve dinamik özellikler eklemek için kullanılır. Blogda, iOS yazılım geliştiricilerin kullandığı diller, araçlar ve platforma özgü en iyi uygulamalar ayrıntılı bir şekilde ele alınıyor."
    },
    { 
      title: "Android Yazılımcı Hangi Yazılım Dillerini Kullanır?", 
      type: "gray", 
      category: "mobile_application", 
      detail: "Android geliştirme dünyası, açık kaynaklı yapısı ve geniş kullanıcı kitlesiyle büyük bir popülerliğe sahiptir. Android yazılım geliştirmede en çok kullanılan diller arasında Java ve Kotlin yer alır. Java, Android'in temel dili olarak yıllardır tercih edilmektedir ve güçlü bir topluluk desteğine sahiptir. Kotlin ise Google tarafından önerilen, modern ve güvenli bir dildir. Kotlin’in sunduğu daha kısa ve anlaşılır kod yazma imkanları, geliştiriciler için büyük bir avantaj sağlar. Android Studio, resmi geliştirme ortamı olarak, kod yazmadan tasarıma kadar tüm süreçlerde kullanıcıya yardımcı olur. Ayrıca, Jetpack kütüphaneleri ve Material Design gibi araçlar, uygulamaların performansını artırırken estetik bir tasarım sunmayı kolaylaştırır. Blogda, Android yazılım geliştirme sürecinin temelleri ve kullanılan dillerin avantajları detaylıca açıklanıyor."
    },
    { 
      title: "UI&UX Tasarımcısı Hangi Programları Kullanır?", 
      type: "black", 
      category: "graphic_and_design", 
      detail: "UI (Kullanıcı Arayüzü) ve UX (Kullanıcı Deneyimi) tasarımı, dijital ürünlerin kullanıcı dostu ve görsel açıdan çekici hale getirilmesinde kritik bir rol oynar. UI&UX tasarımcıları, bu süreçte birçok yazılım aracını kullanır. Figma, Adobe XD ve Sketch, en popüler tasarım araçlarından bazılarıdır. Figma, özellikle ekip çalışması ve bulut tabanlı yapısıyla öne çıkar. Adobe XD, prototip oluşturma ve tasarım sürecini kolaylaştıran güçlü bir araçtır. Sketch ise sade ve minimalist yapısıyla tasarımcılar arasında tercih edilir. Bunun yanı sıra Photoshop ve Illustrator gibi grafik tasarım yazılımları, detaylı ve yaratıcı görseller oluşturmak için kullanılır. Blog, UI&UX tasarımcılarının hangi araçları kullandığını, bu araçların özelliklerini ve hangi durumlarda tercih edildiğini kapsamlı bir şekilde ele alıyor."
    },
    { 
      title: "Robotik Süreç Otomasyonunda (RPA) Uzman Olmak?", 
      type: "pink", 
      category: "rpa_software", 
      detail: "Robotik Süreç Otomasyonu (RPA), işletmelerde manuel ve tekrarlayan görevlerin yazılım robotları aracılığıyla otomatikleştirilmesini sağlar. Bu süreç, zamandan tasarruf ederek işletmelerin verimliliğini artırır ve hata oranını minimuma indirir. Bir RPA uzmanı, iş süreçlerini analiz eder ve en uygun otomasyon çözümlerini geliştirir. UiPath, Automation Anywhere ve Blue Prism gibi araçlar, bu alanda yaygın olarak kullanılır. Özellikle UiPath, kullanıcı dostu arayüzü ve geniş kütüphane desteğiyle popülerdir. RPA uzmanı olmak için analitik düşünme becerileri, kodlama bilgisi ve süreç optimizasyonu konularında yetkin olmak önemlidir. Blogda, RPA'nin işletmeler için sunduğu avantajlar, bir RPA uzmanının rolü ve kariyer olanakları detaylı bir şekilde inceleniyor."
    },
    { 
      title: "UI&UX Tasarımcısı Kimdir?", 
      type: "gray", 
      category: "graphic_and_design", 
      detail: "UI&UX tasarımcıları, dijital ürünlerin hem görsel hem de işlevsel açıdan en iyi şekilde çalışmasını sağlar. Kullanıcı deneyimi (UX), bir ürünün kullanıcılarla nasıl etkileşim kurduğuna odaklanırken; kullanıcı arayüzü (UI), ürünün görsel tasarımını ele alır. UI&UX tasarımcıları, kullanıcı ihtiyaçlarını analiz ederek işlevsel ve çekici arayüzler tasarlar. Tasarım süreci, wireframe oluşturma, prototipleme ve kullanıcı testleri gibi adımları içerir. Bu süreçte Figma, Adobe XD ve Sketch gibi araçlar yaygın olarak kullanılır. Ayrıca, kullanıcı araştırmaları ve veri analizi, UX tasarımcılarının kararlarını destekler. Blog, bir UI&UX tasarımcısının görevlerini, çalışma sürecini ve bu alanda başarılı olmak için gereken yetkinlikleri detaylı bir şekilde açıklıyor."
    },
    { 
      title: "Yazılıma Yeni Başlayanlara Altın Tavsiyeler!", 
      type: "black", 
      category: "web_application", 
      detail: "Yazılıma başlamak, genellikle zor ve karmaşık bir süreç gibi görünse de doğru yaklaşımlar ve araçlarla oldukça keyifli bir hale gelebilir. Bu blog, yazılıma yeni başlayanların dikkat etmesi gereken temel adımları ele alıyor. İlk olarak, öğrenmek istediğiniz programlama dilini seçmek büyük önem taşır. Python, JavaScript veya C# gibi diller başlangıç için uygundur. Ayrıca, bol bol proje yaparak pratik yapmak öğrenmeyi hızlandırır. GitHub gibi platformlarda projelerinizi paylaşarak toplulukla etkileşim kurabilirsiniz. Modern araçlar ve IDE'ler (örneğin VS Code), kod yazmayı ve hata ayıklamayı kolaylaştırır. Blogda, yazılıma başlama sürecini kolaylaştıracak kaynaklar, teknikler ve öneriler detaylı bir şekilde anlatılıyor."
    }
  ];
  
  
  const selectBlog = (item : Blog) => {
    setSelectedBlog(item)
    setIsOpen(true)

    const elements = document.getElementsByClassName("ReactModalPortal");
    if (elements.length > 0) {
      elements[0].classList.add(item.type); // Yeni class ekle
    }
  }

  useEffect(()=>{
    if (!modalIsOpen && selectedBlog) {
      const elements = document.getElementsByClassName("ReactModalPortal");
      elements[0].classList.remove(selectedBlog.type); // Mevcut class'ı kaldır
    }
  },[modalIsOpen])


  const getCategory = (category:string) => {
    switch(category) {
      case "web_application":
        return <ServicesIcon1/>
      case "mobile_application":
        return <ServicesIcon2/>
      case "rpa_software":
        return <ServicesIcon3/>
      case "cyber_security":
        return <ServicesIcon4/>
      case "graphic_and_design":
        return <ServicesIcon5/>
      case "digital_marketing":
        return <ServicesIcon6/>
    }
  }

  return (
    <div className="blogs">
      <div className="blogs-container">
        {blogs.map((item, index)=>
          <div className={"blog-item " + item.type} key={index} onClick={()=> selectBlog(item)}>
            <div className="icon">
              {getCategory(item.category)}
            </div>
            <h3 className="title">{item.title}</h3>
            <p className="detail">Detay İçin Tıklayın<HeaderMenuStart /></p>
          </div>
        )}
      </div>

    <Modal
      style={customStyles}
      closeTimeoutMS={500}
      onRequestClose={()=> setIsOpen(false)}
      contentLabel="Example Modal"
      isOpen={modalIsOpen}
    >
      <div className="modal-content">
        <button className="close" onClick={()=> setIsOpen(false)}>
          <CloseIcon></CloseIcon>
        </button>
        <h2>{selectedBlog?.title}</h2>
        <div className="icon">
          { selectedBlog && getCategory(selectedBlog?.category)}
        </div>
        <p>{selectedBlog?.detail}</p>
      </div>
    </Modal>
      
    </div>
  );
};

export default BlogPage;
