import ComingSoon from "components/comingSoon";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const CareerPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("careerPageTitle");
  }, []);

  return (
    <div className="career">
      <ComingSoon
        title={t("headerMenuCareer")}
        targetDate={new Date("December 31, 2025 23:59:59").getTime()}
      ></ComingSoon>
    </div>
  );
};

export default CareerPage;
